import { SVGColors } from '@styles/Colors'
import { Sprinkles } from '@styles/sprinkles.css'
import { CatalogFileInput, FileMetadataFileType } from '@type/API/generated/models'
import { CSSProperties } from 'react'

export enum FileImageVariants {
  exploration = 'Exploration.svg',
  vc_file = 'VC_file.svg',
  dashboard_file = 'Dashboard_file.svg',
  voyager_file = 'Voyager_file.svg',
  generic_file = 'Generic_file.svg',
  exposure_report = 'Exposure_Report.svg',
  catalogFileProduct = 'Catalog_File_Product.svg',
  catalogFileSupplier = 'Catalog_File_Supplier.svg',
}

type FileImageProps = {
  className?: string
  alt?: string
  type: keyof typeof FileImageVariants
  style?: CSSProperties
  color?: SVGColors
  height?: Sprinkles['height']
  width?: Sprinkles['width']
  onClick?: () => void
}

export const FileImage = (props: FileImageProps) => {
  const src = `/svg/${FileImageVariants[props.type]}`
  const parts = src.split(/\.|\//)
  const altText = props.alt || parts[parts.length - 2] + ' icon'
  const cursor = props.onClick ? 'pointer' : undefined
  const style = {
    ...props.style,
    filter: props.color,
    display: 'inline',
    cursor,
    ...(!!props.width && { minWidth: `${props.width}px` }),
  }

  return (
    <img
      className={props.className}
      style={style}
      src={src}
      height={props.height ?? 20}
      width={props.width ?? 20}
      alt={altText}
      onClick={props.onClick}
      {...props}
    />
  )
}

export type FileIconProps = {
  file_type: FileMetadataFileType
  view_type?: CatalogFileInput['view_type']
  size?: 'xs' | 'sm' | 'lg'
  className?: string
}

const fileIconByType: Record<Exclude<FileMetadataFileType, 'CATALOG'>, keyof typeof FileImageVariants> = {
  EXPLORATION: 'exploration',
  VALUE_CHAIN: 'vc_file',
}
export const variantByCatalogFileViewType: Record<CatalogFileInput['view_type'], keyof typeof FileImageVariants> = {
  PRODUCT: 'catalogFileProduct',
  PRODUCT_VARIANT: 'catalogFileProduct',
  SUPPLIER: 'catalogFileSupplier',
  SUPPLIER_UPSTREAM: 'catalogFileSupplier',
}

export const FileIcon = ({ file_type, view_type, size = 'lg', className = '' }: FileIconProps) => {
  const variant =
    file_type !== 'CATALOG'
      ? fileIconByType[file_type]
      : view_type && view_type in variantByCatalogFileViewType
      ? variantByCatalogFileViewType[view_type]
      : undefined

  return (
    <FileImage
      type={variant || 'generic_file'}
      width={size === 'lg' ? 32 : size === 'sm' ? 24 : 16}
      className={className}
    />
  )
}
