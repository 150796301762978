/**
 *
 * @param str Provide the base word part to be pluralized - example 'Facilit' for 'Facilities' or 'Facility'
 * @param plural Provide the plural word part to be used - example 'ies' for 'Facilities'; if nothing is provided, default is 's'
 * @param singular Provide the singular word part to be used - example 'y' for 'Facilit'; if nothing is provided, default is ''
 * @param reverse_rule If true, the rule is reversed (!== 1 will use the singular form, and === 1 will use the plural form) use this for verbs - Example 1 needs review vs 10 need review.
 *
 */
export const formatPlurals = (
  string: string,
  count: number,
  { plural = 's', singular = '', reverse_rule = false } = {},
): string =>
  reverse_rule ? `${string}${count === 1 ? plural : singular}` : `${string}${count !== 1 ? plural : singular}`

type SimplePluralizer = (count: number) => string

export const commonPlurals = {
  good: count => formatPlurals('good', count),
  supplier: count => formatPlurals('supplier', count),
  valueChain: count => formatPlurals('value chain', count),
  facility: count => formatPlurals('facilit', count, { singular: 'y', plural: 'ies' }),
  company: count => formatPlurals('compan', count, { singular: 'y', plural: 'ies' }),
} satisfies Record<string, SimplePluralizer>
