import { CatalogFileMetadata } from '@type/API/generated/models'
import { formatDistanceToNowStrict } from 'date-fns'
import { File } from './File'
import { variantByCatalogFileViewType } from './components/FileIcon'
import { isStatVisible, StatsSection } from './components/StatsSection'

const isStatSectionVisible = <T extends Pick<CatalogFileMetadata, 'diff_counts'>>(file: T): boolean =>
  'diff_counts' in file &&
  file.diff_counts &&
  (isStatVisible(file.diff_counts.exposure_diff_counts) || isStatVisible(file.diff_counts.supplier_diff_counts))

export const CatalogFileItem = <
  T extends Pick<CatalogFileMetadata, 'view_type' | 'name' | 'diff_counts' | 'updated_at_utc_ms' | 'id'>,
>({
  file,
}: {
  file: T
}) => (
  <File
    name={file.name}
    fileIcon={variantByCatalogFileViewType[file.view_type]}
    href={`/catalog/file/${file.id}`}
    metaDataContent={
      file.updated_at_utc_ms && (
        <div>Last Modified: {formatDistanceToNowStrict(file.updated_at_utc_ms, { addSuffix: true })}</div>
      )
    }
  >
    <>{isStatSectionVisible(file) && <StatsSection diffCountsContainer={file.diff_counts} />}</>
  </File>
)
