import { formatDistanceToNowStrict } from 'date-fns'
import { File } from './File'
import { FileMetadata } from '@type/API/generated/models'

export const ExplorationSharedFileItem = <
  T extends Pick<FileMetadata, 'updated_at_utc_ms' | 'name' | 'id'> & { owner_name?: string },
>({
  file,
}: {
  file: T
}) => (
  <File
    name={file.name}
    fileIcon="exploration"
    href={`/explorations/${file.id}`}
    metaDataContent={
      <>
        {file.updated_at_utc_ms && (
          <div>Last Modified: {formatDistanceToNowStrict(file.updated_at_utc_ms, { addSuffix: true })}</div>
        )}
        {file.owner_name && <div>Owned By: {file.owner_name}</div>}
      </>
    }
  ></File>
)
