import { sprinkles } from '@styles/sprinkles.css'
import { DiffCounts, DiffCountsContainer } from '@type/API/generated/models'
import { Stat } from './Stat'

export const isStatVisible = (diffCounts?: DiffCounts): boolean =>
  !diffCounts || (!diffCounts.added && !diffCounts.removed && !diffCounts.updated) ? false : true

export type StatsSectionProps = {
  diffCountsContainer: DiffCountsContainer
}

export const StatsSection = ({ diffCountsContainer }: StatsSectionProps) => (
  <div className={StatsContainer}>
    <div className={StatsHeader}>Recent Changes to</div>
    <div className={Stats}>
      {isStatVisible(diffCountsContainer.exposure_diff_counts) && (
        <Stat title="Exposure" diffCounts={diffCountsContainer.exposure_diff_counts} />
      )}
      {isStatVisible(diffCountsContainer.supplier_diff_counts) && (
        <Stat title="Supplier" diffCounts={diffCountsContainer.supplier_diff_counts} />
      )}
    </div>
  </div>
)

const StatsContainer = sprinkles({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 8,
  paddingRight: 16,
  paddingBottom: 16,
  paddingLeft: 16,
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  gap: 8,
})

const Stats = sprinkles({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: 4,
  flexWrap: 'wrap',
})

const StatsHeader = sprinkles({
  color: 'gray-8',
  fontStyle: 'body-sm',
})
