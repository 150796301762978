import { sprinkles } from '@styles/sprinkles.css'
import { FileListContainer } from './FileList.css'
import { PropsWithChildren, Children } from 'react'

export const FileList = ({ children }: PropsWithChildren) => {
  const childrenArray = Children.toArray(children)

  if (!childrenArray.length || !children) return <div className={EmptyState}>No files</div>

  return <div className={FileListContainer}>{children}</div>
}

const EmptyState = sprinkles({ padding: 24, textAlign: 'center', color: 'gray-7' })
