import { FileContainer, FileHeaderContainer, FileHeader, FileTitleHeaderText, FileMetadataContainer } from './File.css'
import { PropsWithChildren, ReactNode } from 'react'
import Link from 'next/link'
import { FileImage, FileImageVariants } from './components/FileIcon'

type LinkWrapperProps = {
  href?: string
} & PropsWithChildren

const LinkWrapper = ({ href = '', children }: LinkWrapperProps) => (
  <Link href={href} target="_blank" rel="noopener noreferrer" className={FileContainer} aria-label={`Open ${href}`}>
    {children}
  </Link>
)

type ButtonWrapperProps = {
  onClick?: () => void
  name: string
} & PropsWithChildren

const ButtonWrapper = ({ onClick, children, name }: ButtonWrapperProps) => (
  <button className={FileContainer} onClick={onClick} aria-label={`Open ${name}`}>
    {children}
  </button>
)

export type FileProps = {
  onClick?: () => void
  href?: string
  children?: JSX.Element
  name: string
  fileIcon?: keyof typeof FileImageVariants
  fileIconSize?: 'xs' | 'sm' | 'lg'
  metaDataContent?: ReactNode
}

export const File = ({
  name,
  fileIcon,
  onClick,
  href,
  children,
  fileIconSize = 'sm',
  metaDataContent,
}: PropsWithChildren<FileProps>) => {
  const WrapperElement = href ? LinkWrapper : ButtonWrapper

  return (
    <WrapperElement href={href} onClick={onClick} name={name}>
      <div className={FileHeaderContainer}>
        <div className={FileHeader}>
          <div>
            <FileImage
              type={fileIcon || 'generic_file'}
              width={fileIconSize === 'lg' ? 32 : fileIconSize === 'sm' ? 24 : 16}
            />
          </div>
          <div className={FileTitleHeaderText}>{name}</div>
        </div>
      </div>
      {children}
      {metaDataContent && <div className={FileMetadataContainer}>{metaDataContent}</div>}
    </WrapperElement>
  )
}
