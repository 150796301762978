import { sprinkles } from '@styles/sprinkles.css'
import { DiffCounts } from '@type/API/generated/models'
import clsx from 'clsx'
import { StatStyle } from './Stat.css'
import { isNumber } from 'lodash'
import { formatPlurals } from '@utils/formatPlurals'

const compactNumberAbbreviator = new Intl.NumberFormat(undefined, {
  notation: 'compact',
  maximumFractionDigits: 1,
})

type StatProps = {
  title: string
  diffCounts?: DiffCounts
}

export const Stat = ({ title, diffCounts }: StatProps) => {
  // diffCounts values can be undefined/None!
  const added = diffCounts && isNumber(diffCounts.added) ? diffCounts.added : 0
  const removed = diffCounts && isNumber(diffCounts.removed) ? diffCounts.removed : 0
  const updated = diffCounts && isNumber(diffCounts.updated) ? diffCounts.updated : 0
  const total = added + removed + updated
  const formattedTitle = formatPlurals(title, total)

  return (
    <div
      className={clsx(
        StatStyle,
        sprinkles({
          display: 'flex',
          paddingY: 0,
          borderRadius: 4,
          backgroundColor: 'gray-1',
        }),
      )}
    >
      <span
        className={sprinkles({
          color: 'gray-10',
          fontStyle: 'heading-sm',
        })}
      >
        {compactNumberAbbreviator.format(total)}{' '}
        <span
          className={sprinkles({
            fontWeight: 400,
          })}
        >
          {formattedTitle}
        </span>
      </span>
    </div>
  )
}
